.canvas {
  border: 1px solid red;
}

.form-switch-md {
  padding-left: 3.5em;
}
.form-switch-md input.form-check-input {
  width: 3em;
  height: 1.5em;
  margin-left: -3.5em;
}
.form-switch-md label.form-check-label {
  height: 1.5em;
  vertical-align: sub;
}
